import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import useUser from 'shared/hooks/use-user'
import BellIcon from 'shared/icons/bell-icon'
import DoubleCheckmarkIcon from 'shared/icons/double-checkmark-icon'
import SettingsIcon from 'shared/icons/settings-icon'
import ThinArrowRightIcon from 'shared/icons/thin-arrow-right-icon'
import { useFilterPagination } from 'shared/route-query/hooks/use-filter-pagination'
import { isCustomDomain } from 'shared/types/is-custom-domain'
import { getDashboardNotificationSettingsLink } from 'modules/header/menu/utils'
import NotificationItem from 'modules/profile/notifications/components/notification-item'
import NotificationEmptyList from 'modules/profile/notifications/components/notification-list-empty'
import NotificationsListSkeleton from 'modules/profile/notifications/components/notifications-list-skeleton'
import UnreadIndicator from 'modules/profile/notifications/components/unread-indicator'
import { useNotificationsActions } from 'modules/profile/notifications/hooks/use-notification-actions'
import { useNotifications } from 'modules/profile/notifications/hooks/use-notifications'

export interface NotificationMenuProps {
  className?: string
  isCustomer?: boolean
  iconClassname?: string
  activeIconClassname?: string
}

const defaultFilter = {
  onlyUnRead: false,
}

const NotificationMenuMobile = ({
  className,
  isCustomer = false,
  iconClassname,
  activeIconClassname,
}: NotificationMenuProps) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [shouldFetch, setShouldFetch] = useState(false)

  const toggleMenuState = useCallback(() => {
    setIsMenuOpen(prevState => !prevState)
  }, [])

  const { pagination } = useFilterPagination({
    defaultFilter,
    isStaticUrl: true,
    initLimit: 3,
  })
  const { notificationsMeta, notifications, isFetching } = useNotifications({
    shouldFetch,
    limit: 3,
    pagination,
    filter: defaultFilter,
  })
  const { markAllNotificationsAsRead } = useNotificationsActions()

  return (
    <>
      <button
        onClick={toggleMenuState}
        onMouseEnter={() => {
          setShouldFetch(true)
        }}
        className="flex h-full items-center justify-around gap-2.5 border-b-4 border-transparent focus:outline-none focus-visible:border-blue"
      >
        <div className="relative w-5 text-sm font-bold text-white/90">
          <BellIcon
            className={twMerge(
              'fill-gray-400 stroke-gray-400 transition hover:fill-white hover:stroke-white',
              iconClassname,
              isMenuOpen && twMerge('fill-white stroke-white', activeIconClassname),
            )}
          />
          {notificationsMeta?.hasUnread && (
            <UnreadIndicator className="absolute right-0 top-0 p-0" />
          )}
        </div>
      </button>
      <Transition show={isMenuOpen} as={Fragment}>
        <Dialog open={isMenuOpen} onClose={toggleMenuState}>
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex w-full pt-[69px]">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  className={twMerge(
                    'relative w-full transform bg-white text-left shadow-xl transition-all',
                    className,
                  )}
                >
                  <div className="flex w-full flex-col bg-white py-4 text-sm shadow-lg">
                    <div className="flex flex-row justify-between px-6">
                      <h4 className="text-[18px] text-darkblue">
                        {t('profile.user_settings.notifications.title')}
                      </h4>
                      <div className="inline-flex flex-row items-baseline gap-1 stroke-darkblue text-darkblue transition hover:stroke-blue hover:text-blue">
                        <DoubleCheckmarkIcon />
                        <span className="cursor-pointer" onClick={markAllNotificationsAsRead}>
                          {t('profile.user_settings.notifications.mark_all_read')}
                        </span>
                      </div>
                    </div>
                    {isFetching ? (
                      <NotificationsListSkeleton />
                    ) : !!notifications?.items.length ? (
                      notifications?.items.map(notification => (
                        <NotificationItem key={notification.id} notification={notification} />
                      ))
                    ) : (
                      <NotificationEmptyList />
                    )}
                    <div className="flex justify-between px-6 pt-4 text-right">
                      <LinkWithoutPrefetch
                        className="primary-link inline-flex flex-row items-center gap-1 stroke-darkblue text-darkblue hover:stroke-blue hover:text-blue"
                        href={
                          isCustomer && !isCustomDomain
                            ? getDashboardNotificationSettingsLink(user)
                            : '/profile/notification-settings'
                        }
                      >
                        <SettingsIcon width={18} height={18} />
                        <span className="hidden sm:inline">
                          {t('profile.user_settings.manage_notifications.title')}
                        </span>
                      </LinkWithoutPrefetch>
                      <LinkWithoutPrefetch
                        className="primary-link inline-flex flex-row items-center gap-3 stroke-darkblue text-darkblue hover:stroke-blue hover:text-blue"
                        onClick={toggleMenuState}
                        href="/profile/notifications"
                      >
                        <h4 className="capitalize">
                          {t('profile.user_settings.notifications.view_all')}
                        </h4>
                        <ThinArrowRightIcon />
                      </LinkWithoutPrefetch>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default NotificationMenuMobile

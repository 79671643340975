import { Float } from '@headlessui-float/react'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import useUser from 'shared/hooks/use-user'
import BellIcon from 'shared/icons/bell-icon'
import DoubleCheckmarkIcon from 'shared/icons/double-checkmark-icon'
import SettingsIcon from 'shared/icons/settings-icon'
import ThinArrowRightIcon from 'shared/icons/thin-arrow-right-icon'
import { useFilterPagination } from 'shared/route-query/hooks/use-filter-pagination'
import { isCustomDomain } from 'shared/types/is-custom-domain'
import { getDashboardNotificationSettingsLink } from 'modules/header/menu/utils'
import NotificationItem from 'modules/profile/notifications/components/notification-item'
import NotificationEmptyList from 'modules/profile/notifications/components/notification-list-empty'
import NotificationsListSkeleton from 'modules/profile/notifications/components/notifications-list-skeleton'
import UnreadIndicator from 'modules/profile/notifications/components/unread-indicator'
import { useNotificationsActions } from 'modules/profile/notifications/hooks/use-notification-actions'
import { useNotifications } from 'modules/profile/notifications/hooks/use-notifications'

export interface NotificationMenuProps {
  className?: string
  isCustomer?: boolean
  iconClassname?: string
  activeMenuIconClassname?: string
}

const defaultFilter = {
  onlyUnRead: false,
}

const NotificationMenu = ({
  className,
  isCustomer = false,
  iconClassname,
  activeMenuIconClassname,
}: NotificationMenuProps) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [shouldFetch, setShouldFetch] = useState(false)

  const { pagination } = useFilterPagination({
    defaultFilter,
    isStaticUrl: true,
    initLimit: 3,
  })
  const { notificationsMeta, notifications, isFetching } = useNotifications({
    shouldFetch,
    limit: 3,
    pagination,
    filter: defaultFilter,
  })
  const { markAllNotificationsAsRead } = useNotificationsActions()

  const { show, delayClose, delayOpen } = useFloatHover()

  return (
    <Float
      portal
      show={show}
      placement="bottom-end"
      offset={{
        mainAxis: 30,
        crossAxis: 135,
      }}
      enter="transition duration-100 ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition duration-75 ease-in"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={twMerge('flex h-full items-center text-darkblue', className)}
    >
      <button
        onMouseEnter={() => {
          setShouldFetch(true)
          delayOpen()
        }}
        onMouseLeave={delayClose}
        className="flex h-full items-center justify-around gap-2.5 self-center border-b-4 border-transparent focus:outline-none focus-visible:border-blue"
      >
        <div className="relative w-5 text-sm font-bold text-white/90">
          <BellIcon
            className={twMerge(
              'h-[18px] w-[18px] fill-gray-200 stroke-gray-200 transition hover:fill-white hover:stroke-white',
              iconClassname,
              show && twMerge('fill-white stroke-white', activeMenuIconClassname),
            )}
          />
          {notificationsMeta?.hasUnread && (
            <UnreadIndicator className="absolute right-0 top-0 p-0" iconClassName="w-2 h-2" />
          )}
        </div>
      </button>

      <div
        onMouseEnter={delayOpen}
        onMouseLeave={delayClose}
        className="flex w-[500px] flex-col rounded-lg bg-white py-4 text-sm shadow-lg"
      >
        <div className="flex flex-row justify-between px-6">
          <h4 className="text-[18px] text-darkblue">
            {t('profile.user_settings.notifications.title')}
          </h4>
          <div className="inline-flex flex-row items-baseline gap-1 stroke-darkblue text-darkblue transition hover:stroke-blue hover:text-blue">
            <DoubleCheckmarkIcon />
            <span className="cursor-pointer" onClick={markAllNotificationsAsRead}>
              {t('profile.user_settings.notifications.mark_all_read')}
            </span>
          </div>
        </div>
        {isFetching ? (
          <NotificationsListSkeleton />
        ) : !!notifications?.items.length ? (
          notifications?.items.map(notification => (
            <NotificationItem key={notification.id} notification={notification} />
          ))
        ) : (
          <NotificationEmptyList />
        )}
        <div className="flex justify-between px-6 pt-4">
          <LinkWithoutPrefetch
            className="primary-link inline-flex flex-row items-center gap-1 stroke-darkblue text-darkblue hover:stroke-blue hover:text-blue"
            href={
              isCustomer && !isCustomDomain
                ? getDashboardNotificationSettingsLink(user)
                : '/profile/notification-settings'
            }
          >
            <SettingsIcon width={18} height={18} />
            <span className="hidden sm:inline">
              {t('profile.user_settings.manage_notifications.title')}
            </span>
          </LinkWithoutPrefetch>
          <LinkWithoutPrefetch
            className="primary-link inline-flex flex-row items-center gap-3 stroke-darkblue text-darkblue hover:stroke-blue hover:text-blue"
            href="/profile/notifications"
          >
            <h4 className="capitalize">{t('profile.user_settings.notifications.view_all')}</h4>
            <ThinArrowRightIcon />
          </LinkWithoutPrefetch>
        </div>
      </div>
    </Float>
  )
}

export default NotificationMenu

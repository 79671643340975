import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import useSWR from 'swr'
import { getPaginationAndFilterQueryString } from 'shared/api/utils/get-pagination-and-filter-query-string'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { SwrPaginationInterface } from 'shared/types/swr-pagination-interface'
import { getNotificationApiBaseUrl } from 'shared/utils/get-notification-api-base-url'
import {
  NotificationsApiInterface,
  NotificationsFilterInterface,
} from 'modules/profile/notifications/types/notification-types'

interface UseNotificationsInterface extends SwrPaginationInterface {
  shouldFetch?: boolean
  filter: NotificationsFilterInterface
}

export interface HasUnreadResponse {
  hasUnread: boolean
}

export const useNotifications = ({
  limit,
  shouldFetch = true,
  pagination,
  filter,
}: UseNotificationsInterface) => {
  const { t } = useTranslation()

  const notFoundResponseHandler = useCallback(() => {
    toast.error(t('profile.notifications.error_loading_notifications'))
  }, [t])

  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    NotificationsApiInterface
  >({
    method: RequestMethodsEnum.get,
    notFoundResponseHandler,
  })

  const { fetcher: unreadFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    HasUnreadResponse
  >({
    method: RequestMethodsEnum.get,
    notFoundResponseHandler,
  })

  const {
    data: notifications,
    mutate,
    isLoading,
  } = useSWR<NotificationsApiInterface>(() => {
    if (user && shouldFetch) {
      return `${getNotificationApiBaseUrl()}/notifications?${getPaginationAndFilterQueryString({
        limit,
        pagination,
        filter: { ...filter },
      })}`
    }
    return null
  }, fetcher)

  const {
    data: notificationsMeta,
    mutate: mutateNotificationsMeta,
    isLoading: isLoadingNotificationsMeta,
  } = useSWR<HasUnreadResponse>(() => {
    if (user) {
      return `${getNotificationApiBaseUrl()}/notifications/has-unread`
    }
    return null
  }, unreadFetcher)

  return {
    notificationsMeta,
    mutateNotificationsMeta,
    notifications,
    isFetching: isLoading || isLoadingNotificationsMeta,
    mutate,
  }
}
